exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-book-a-demo-index-js": () => import("./../../../src/pages/book-a-demo/index.js" /* webpackChunkName: "component---src-pages-book-a-demo-index-js" */),
  "component---src-pages-booking-system-index-js": () => import("./../../../src/pages/booking-system/index.js" /* webpackChunkName: "component---src-pages-booking-system-index-js" */),
  "component---src-pages-calendar-and-scheduling-index-js": () => import("./../../../src/pages/calendar-and-scheduling/index.js" /* webpackChunkName: "component---src-pages-calendar-and-scheduling-index-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-customer-profile-index-js": () => import("./../../../src/pages/customer-profile/index.js" /* webpackChunkName: "component---src-pages-customer-profile-index-js" */),
  "component---src-pages-dashboard-and-data-index-js": () => import("./../../../src/pages/dashboard-and-data/index.js" /* webpackChunkName: "component---src-pages-dashboard-and-data-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-allied-health-js": () => import("./../../../src/pages/industry/allied-health.js" /* webpackChunkName: "component---src-pages-industry-allied-health-js" */),
  "component---src-pages-industry-car-rentals-js": () => import("./../../../src/pages/industry/car-rentals.js" /* webpackChunkName: "component---src-pages-industry-car-rentals-js" */),
  "component---src-pages-industry-care-services-js": () => import("./../../../src/pages/industry/care-services.js" /* webpackChunkName: "component---src-pages-industry-care-services-js" */),
  "component---src-pages-industry-chiropractic-js": () => import("./../../../src/pages/industry/chiropractic.js" /* webpackChunkName: "component---src-pages-industry-chiropractic-js" */),
  "component---src-pages-industry-counselling-js": () => import("./../../../src/pages/industry/counselling.js" /* webpackChunkName: "component---src-pages-industry-counselling-js" */),
  "component---src-pages-industry-customers-and-staff-js": () => import("./../../../src/pages/industry/customers-and-staff.js" /* webpackChunkName: "component---src-pages-industry-customers-and-staff-js" */),
  "component---src-pages-industry-education-js": () => import("./../../../src/pages/industry/education.js" /* webpackChunkName: "component---src-pages-industry-education-js" */),
  "component---src-pages-industry-general-practice-js": () => import("./../../../src/pages/industry/general-practice.js" /* webpackChunkName: "component---src-pages-industry-general-practice-js" */),
  "component---src-pages-industry-maintenance-js": () => import("./../../../src/pages/industry/maintenance.js" /* webpackChunkName: "component---src-pages-industry-maintenance-js" */),
  "component---src-pages-industry-mental-health-js": () => import("./../../../src/pages/industry/mental-health.js" /* webpackChunkName: "component---src-pages-industry-mental-health-js" */),
  "component---src-pages-industry-primary-care-js": () => import("./../../../src/pages/industry/primary-care.js" /* webpackChunkName: "component---src-pages-industry-primary-care-js" */),
  "component---src-pages-industry-risk-assessments-js": () => import("./../../../src/pages/industry/risk-assessments.js" /* webpackChunkName: "component---src-pages-industry-risk-assessments-js" */),
  "component---src-pages-platform-privacy-policy-js": () => import("./../../../src/pages/platform/privacy-policy.js" /* webpackChunkName: "component---src-pages-platform-privacy-policy-js" */),
  "component---src-pages-platform-terms-of-service-js": () => import("./../../../src/pages/platform/terms-of-service.js" /* webpackChunkName: "component---src-pages-platform-terms-of-service-js" */),
  "component---src-pages-pricing-components-request-pricing-button-js": () => import("./../../../src/pages/pricing/components/RequestPricingButton.js" /* webpackChunkName: "component---src-pages-pricing-components-request-pricing-button-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-product-billing-and-invoicing-js": () => import("./../../../src/pages/product/billing-and-invoicing.js" /* webpackChunkName: "component---src-pages-product-billing-and-invoicing-js" */),
  "component---src-pages-product-calendar-and-scheduling-js": () => import("./../../../src/pages/product/calendar-and-scheduling.js" /* webpackChunkName: "component---src-pages-product-calendar-and-scheduling-js" */),
  "component---src-pages-product-client-management-js": () => import("./../../../src/pages/product/client-management.js" /* webpackChunkName: "component---src-pages-product-client-management-js" */),
  "component---src-pages-product-customisable-booking-pages-js": () => import("./../../../src/pages/product/customisable-booking-pages.js" /* webpackChunkName: "component---src-pages-product-customisable-booking-pages-js" */),
  "component---src-pages-product-customisable-survey-forms-js": () => import("./../../../src/pages/product/customisable-survey-forms.js" /* webpackChunkName: "component---src-pages-product-customisable-survey-forms-js" */),
  "component---src-pages-product-electronic-health-records-js": () => import("./../../../src/pages/product/electronic-health-records.js" /* webpackChunkName: "component---src-pages-product-electronic-health-records-js" */),
  "component---src-pages-product-medical-and-health-notes-js": () => import("./../../../src/pages/product/medical-and-health-notes.js" /* webpackChunkName: "component---src-pages-product-medical-and-health-notes-js" */),
  "component---src-pages-reporting-and-ai-index-js": () => import("./../../../src/pages/reporting-and-ai/index.js" /* webpackChunkName: "component---src-pages-reporting-and-ai-index-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-surveys-and-forms-index-js": () => import("./../../../src/pages/surveys-and-forms/index.js" /* webpackChunkName: "component---src-pages-surveys-and-forms-index-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */)
}

